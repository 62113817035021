<template lang="pug">
  .plan-list
    AppOverlayLoader(:state="plansLoading")
    TopBar(
      :filters="filters"
      :otas="allowedOtas"
      :shops="shops"
      :shops-loading="shopsLoading"
      :car-classes="carClasses"
      :car-class-loadin="carClassesLoading"
      @apply-search="applySearch"
      @select-otas="handleSelectOtas"
      @select-shop="handleSelectShop"
      @select-car-classes="handleSelectCarClasses"
      @change-filters="applyFilters"
      @clear-filters="clearFilters"
    )
    .d-flex.justify-content-end.p-t-17.p-b-17
      SyncInProgressNotification(:shown="inProgressImports")
      AppIconButton.get-data-from-ota(
        :disabled="inProgressImports"
        title="plan_management.actions.get_data_from_ota"
        @click="importPlansData"
        icon="reply"
        :use-app-icon="true"
      )
      AppSaveButton.m-l-10(
        disabled
        short
        title="plan_management.actions.save_changes"
        @save=""
      )
    .plans-table(v-if="!isEmpty(plans)")
      PlansTable(
        @sorting="changeSorting"
        :sorting-data="sorting"
        :plans="plans"
      )
      AppPagination(
        :current-page="pagination.current_page"
        :total="pagination.total_count"
        :per-page="pagination.per_page"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  // misc
  import { appDebounce } from "@/helpers/common"
  import { isEmpty } from "lodash-es"

  // mixins
  import withWebSocket from "@/mixins/withWebSocket"
  import planListModule from "@/config/store/plan_management/plan_list"
  import importsModule from "@/config/store/plan_management/imports"
  import shopsModule from "@/config/store/matching/shop"
  import carClassesModule from "@/config/store/matching/car_class"
  import withStoreModule from "@/mixins/withStoreModule"
  import withAllowedOtaList from "@/mixins/withAllowedOtaList"

  const planListMixin = withStoreModule(planListModule, {
    name: "planList",
    readers: {
      sorting: "sorting",
      filters: "filters",
      pagination: "pagination",
      plansLoading: "loading",
      plans: "items",
      inProgressImports: "inProgressImports"
    },
    actions: {
      fetchPlansAction: "FETCH_ITEMS",
      importPlansData: "IMPORT_PLANS_DATA"
    },
    mutations: {
      addItem: "ADD_ITEM",
      resetItems: "RESET_ITEMS",
      updateItemByIndex: "UPDATE_ITEM_BY_INDEX",
      updateAddedItemByIndex: "UPDATE_ADDED_ITEM_BY_INDEX",
      setFilters: "SET_FILTERS",
      resetFilters: "RESET_FILTERS",
      setSorting: "SET_SORTING",
      setPagination: "SET_PAGINATION_DATA"
    }
  })

  const shopsMixin = withStoreModule(shopsModule, {
    name: "shops",
    readers: { shops: "items", shopsLoading: "loading" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const carClassesMixin = withStoreModule(carClassesModule, {
    name: "carClasses",
    readers: { carClasses: "items", carClassesLoading: "loading" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  const importsMixin = withStoreModule(importsModule, {
    name: "planManagementImports",
    readers: { inProgressImports: "inProgressImports" },
    actions: {
      importPlansData: "IMPORT_PLANS_DATA",
      checkInProgressImports: "CHECK_IN_PROGRESS_IMPORTS"
    }
  })

  export default {
    components: {
      PlansTable: () => import("./PlansTable"),
      TopBar: () => import("./TopBar"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      SyncInProgressNotification: () => import("@/pages/InventoryManagement/SyncInProgressNotification.vue")
    },

    mixins: [planListMixin, importsMixin, shopsMixin, carClassesMixin, withAllowedOtaList, withWebSocket],

    created() {
      this.debouncedFetchPlans = appDebounce(() => this.fetchPlansAction())
    },

    mounted() {
      this.checkInProgressImports()
      this.webSocketSubscribe("PlanManagementChannel", {
        received: () => this.checkInProgressImports()
      })
    },

    methods: {
      isEmpty,

      clearFilters() {
        this.resetFilters()
        this.resetItems()
      },

      handleSelectOtas(otas) {
        this.applyFilters({ ...this.filters, otas, shop: undefined, carClasses: [] })
        this.fetchShops({ filters: this.filters, pagination: { _disabled: true } })
      },

      handleSelectShop(shop) {
        this.applyFilters({ ...this.filters, shop, carClasses: [] })
        this.fetchCarClasses({ filters: this.filters, pagination: { _disabled: true } })
      },

      handleSelectCarClasses(carClasses) {
        this.applyFilters({ ...this.filters, carClasses })
      },

      applySearch(searchValue) {
        this.applyFilters({ ...this.filters, searchValue })
      },

      applyFilters(filters) {
        this.setFilters(filters)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.fetchPlans()
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.fetchPlans()
      },

      changeSorting(sorting) {
        this.setSorting(sorting)
        this.fetchPlans()
      },

      fetchPlans() {
        if (this.filtersValid) {
          this.debouncedFetchPlans()
        }
      }
    },

    computed: {
      filtersValid() {
        return !isEmpty(this.selectedShop) && !isEmpty(this.selectedCarClasses)
      },

      selectedCarClasses({ filters }) {
        return filters.carClasses
      },

      selectedShop({ filters }) {
        return filters.shop
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  .plan-list
    .get-data-from-ota
      background-color: $default-purple
      ::v-deep
        .title
          color: $default-white
</style>
