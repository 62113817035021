import { api } from "@/config"
import { handleFailedResponse } from "@/helpers/common"

export default ({ baseURI }) => ({
  IMPORT_PLANS_DATA: async () => {
    try {
      await api.post(baseURI)
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  CHECK_IN_PROGRESS_IMPORTS: async ({ commit }) => {
    try {
      const response = await api.get(`${baseURI}/check_in_progress`)
      commit("SET_IN_PROGRESS_IMPORTS", response.data.data.in_progress_imports)
    } catch (e) {
      handleFailedResponse(e)
    }
  }
})
