import { api } from "@/config"
import { format as dateFormat } from "date-fns"
import { get, isNil, map } from "lodash-es"
import { normalizeSortingParams } from "@/helpers/vue-good-table"
import { DATE_FNS_DATE_FORMAT } from "@/config/constants"

const mappedDateRange = ([start, end]) => {
  if (isNil(start) || isNil(end)) {
    return null
  } else {
    return {
      start: dateFormat(start, DATE_FNS_DATE_FORMAT),
      end: dateFormat(end, DATE_FNS_DATE_FORMAT)
    }
  }
}

export const mapFilters = filters => {
  return {
    ota_ids: map(filters.otas, "id"),
    period_type: get(filters.period_type, "id"),
    shop_id: get(filters.shop, "id"),
    car_class_ids: map(filters.carClasses, "id"),
    date_range: mappedDateRange(filters.dateRange),
    period_inclusion_type: get(filters.periodInclusion, "id"),
    insurance: get(filters.insurance, "id"),
    fee_type: get(filters.timeSlotType, "id"),
    status: get(filters.saleStatus, "id"),
    search_value: filters.searchValue
  }
}

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit, state: { filters, pagination, sorting } }, customParams = {}) => {
    const params = {
      filters: mapFilters(filters),
      pagination,
      sorting: normalizeSortingParams(sorting),
      ...customParams
    }
    const response = await api.get(baseURI, { params })
    const { items, meta } = response.data.data
    commit("SET_ITEMS", items)
    commit("SET_PAGINATION_DATA", meta.pagination)
  }
})
