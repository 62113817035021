import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractActions from "./actions"
import extractMutations from "./mutations"

import { defaultFiltersObject } from "./helpers.js"

const BASE_URI = "/plan_management/sources_plans"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  withFilters: defaultFiltersObject(),
  withPagination: true,
  withSorting: true
})

store.mergeActions(extractActions({ baseURI: BASE_URI }), withLoading)
store.mergeMutations(extractMutations())

export default store
