import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractActions from "./actions"
import extractMutations from "./mutations"

const BASE_URI = "/plan_management/imports"

const store = new StoreItemsModule({
  baseURI: BASE_URI
})

store.mergeState({
  inProgressImports: false
})

store.mergeActions(extractActions({ baseURI: BASE_URI }), withLoading)
store.mergeMutations(extractMutations())

export default store
